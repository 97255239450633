import * as React from "react"
import { AnFadeIn } from "components"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import { Helmet } from "react-helmet"
import { mainSection, pageContent } from "./styles.module.css"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

const Page = ({ data }) => {
  const { title, text } = data.prismicPage.data

  return (
    <main className={mainSection}>
      <AnFadeIn>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Still Curious | ${title.text}`}</title>
        </Helmet>
        <section className={pageContent}>
          <h1>{title.text}</h1>
          <RichText render={text.raw} />
        </section>
      </AnFadeIn>
    </main>
  )
}

export const query = graphql`
  query SinglePageQuery($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      data {
        text {
          raw
        }
        title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
